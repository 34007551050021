import React from "react";
import Layout from "../layout/Layout";
import { staticPage } from "../../config/staticPage";

const UnAuthorized = () => {
  return (
    <>
      <div className="container">
        <h4 className="text-center">You are not authorized to view this page.</h4>
      </div>
    </>
  );
};
export const Head = () => {
  let login_nav = staticPage;
  let loginDetails = null;
  for (const obj of login_nav) {
    if (obj.name === "403") {
      loginDetails = obj;
      break; // Exit the loop once the desired object is found.
    }
  }
  return (
    <>
      <title>{loginDetails.title}</title>
      <meta name="description" content={loginDetails.description} />
    </>
  );
};

UnAuthorized.Layout = Layout
export default UnAuthorized;
